// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';


@font-face {
    font-family: sfmedium;
    src: url('./assets/fonts/Gilroy-Medium.ttf')
}

@font-face {
    font-family: sfbold;
    src: url('./assets/fonts/Gilroy-Bold.ttf')
}

@font-face {
    font-family: semibold;
    src: url('./assets/fonts/Gilroy-SemiBold.ttf')
}

@font-face {
    font-family: slight;
    src: url('./assets/fonts/Gilroy-Light.ttf')
}

a {
    text-decoration: none;
}

.app-body {
    background-color: #fff !important;
}

.main .container-fluid {
    padding: 0 !important;
}

html {
    scroll-behavior: smooth;
}

.bg-custom {
    background: #14354b;
}

.bg-services {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    background: linear-gradient(90deg, #14354b, transparent);
    color: #fff;
    font-size: 4rem;
}

.bg-services h1 {
    text-transform: uppercase;
    font-family: 'sfbold';
}

.services_box img {
    width: 100%;
    border-radius: 15px;
    height: 180px;
    object-fit: cover;
}

.services_box {
    background: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 0;
    border-bottom: 5px solid #14354b;
    transition: all 500ms ease 0s;
    margin-bottom: 20px;
    min-height: 320px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, .2);
}

.services_box h1 {
    font-size: 1.5rem;
    padding-top: 15px;
    font-family: 'sfbold';
    line-height: 30px;
}

.services_box p {
    font-size: 1rem;
    color: #767676;
    font-family: 'sfmedium';
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}

.padding {
    padding-top: 60px;
    padding-bottom: 60px;
}

button.sign_btn {
    background: #fff;
    height: 40px;
    width: 100px;
    font-family: sfbold;
    border-radius: 5px;
    transition: all 500ms ease 0s;
    border: 1px solid #fff;
}

button.sign_btn:hover {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    white-space: nowrap;
    height: 50px;

}

li.nav-item a {
    font-size: 0.9rem;
    color: #fff !important;
    font-family: sfmedium;
    margin-left: 15px;
    cursor: pointer;
}

.padding {
    padding-top: 60px;
    padding-bottom: 60px;
}

h1.links_calss {
    text-align: center;
    font-size: 1.3rem;
    color: #fff;
    padding-top: 7%;
    padding-bottom: 3%;
    font-family: 'sfbold';
}

p.para_email {
    text-align: center;
    line-height: 0;
    color: #fff;
    font-size: 0.9rem;
    padding-top: 24px;
    font-family: 'sfmedium';
    padding-left: 15px;
}

.para_email_1 {
    text-align: center;
    // line-height: 0;
    color: #fff;
    font-size: 0.9rem;
    padding-top: 13px;
    font-family: 'sfmedium';
}

.w-70_bi {
    height: 55px;
}


h1.offer_what {
    font-size: 2.5rem;
    color: #fff;
    font-family: 'sfbold';
    padding-bottom: 15px;
    margin-top: 5%;
}

.under_list {
    padding: 0 !important;
    margin: 0;
    margin-left: 25px;
}

ul.under_list li {
    color: #FFF;
    FONT-SIZE: 1REM;
    font-family: 'sfmedium';
    line-height: 24px;
    margin-bottom: 35px;
}

@media(max-width: 767px) {
    ul.under_list li {
        text-align: center;

    }

    h1.offer_what {
        text-align: center;

    }
}

ul.under_list {
    margin-top: 30px;
}

.bg-image {
    background-image: url('/img/R_DBackground.jpg');
    background-size: cover;
    height: 500px;
}

.services_box_rnd img {
    width: 92%;
    border: 6px solid #fff;
    border-radius: 15px;
    height: 200px;
    object-fit: cover;
}

.services_box_rnd img {
    width: 92%;
    border: 6px solid #fff;
    border-radius: 15px;
    height: 200px;
    object-fit: cover;
}

.services_box_rnd {
    position: relative;
    cursor: pointer;
}

.services_box_rnd h1 {
    position: absolute;
    top: 65px;
    font-size: 1.3rem;
    text-align: center;
    left: 7px;
    right: 27px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: 'sfbold';
    height: 71px;
}

.services_box_rnd:hover h1 {
    background: #fff;
    color: #000;
    top: 0;
    bottom: 0;
    height: 200px;
    border-radius: 15px;
    left: 0;
    right: 0;
    width: 93%;
    padding-top: 25%;
}

ul.footer_social li {
    list-style: none;
    display: inline-block;
}

ul.footer_social li a {
    color: #fff !important;
    font-size: 26px;
    text-align: center;
    margin-left: 23px;
}

.height_footer {
    height: 400px;
    padding-top: 50px;
    position: relative;
}

p.para_copy {
    text-align: center;
    color: #fff;
    font-size: 1rem;
    font-family: 'sfmedium';
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #ccc;
    padding-top: 15px;
    max-width: 1440px;
    margin: 0 auto;
    bottom: 20px;
}

h1.links_calss i {
    font-size: 1rem;
    margin-right: 5px;
    position: relative;
    top: -1px;
}


.group:after {
    content: "";
    display: table;
    clear: both;
}

.grid-1-5 {
    // border: 2px solid #5d4e65;
    min-height: 530px;
    // padding: 1.25em;
    position: relative;
    text-align: center;
    transition: all .2s ease-in-out;
}

@media screen and (min-width: 700px) {
    .grid-1-5 {
        float: left;
        width: 50%;
    }

    .grid-1-5:nth-child(odd) {
        clear: left;
    }
}

@media screen and (min-width: 800px) {
    .grid-1-5 {
        width: 33.3333333%;
    }

    .grid-1-5:nth-child(3n+1) {
        clear: left;
    }

    .grid-1-5:nth-child(odd) {
        clear: none;
    }
}

@media screen and (min-width: 1120px) {
    .grid-1-5 {
        width: 19%;
        margin-right: 10px;



    }

    .grid-1-5:nth-child(odd),
    .grid-1-5:nth-child(3n+1) {
        clear: none;
    }
}


.button {
    background-color: #104a70;
    border-radius: 5px;
    color: #fff !important;
    font-size: 1em;
    font-weight: 700;
    padding: 0.75em 0.5em;
    position: absolute;
    bottom: 1.25em;
    left: 46%;
    margin-left: -60px;
    text-decoration: none;
    width: 140px;
    font-family: 'sfmedium';
}

.uppercase,
.button,
h2 {
    text-transform: uppercase;
}



.grid-1-5.color-bg-p-1 {
    background: #cd7f32;
    border: 2px solid #000;
    border-radius: 10px;
}

.grid-1-5.color-bg-p-2 {
    background: #c0c0c0;
    border: 2px solid #000;
    border-radius: 10px;
}

.grid-1-5.color-bg-p-3 {
    background: #ffd700;
    border: 2px solid #000;
    border-radius: 10px;
}

.grid-1-5.color-bg-p-4 {
    background: #94bda1;
    border: 2px solid #000;
    border-radius: 10px;
}

ul.under_pricinng {
    padding: 0;
    margin: 0;
}

ul.under_pricinng li {
    list-style: none;
    font-size: 1rem;
    font-family: 'sfmedium';
    padding-top: 7px;
    padding-bottom: 7px;
}

ul.under_pricinng li i {
    font-size: 1.4rem;
}

.pricing_tab {
    min-height: 200px;
    text-align: center;
}

.pricing_tab h2 {
    font-size: 1.6rem;
    font-family: 'sfbold';
}

.pricing_tab h3 {
    font-size: 1.5rem;
    text-align: center;
    font-family: 'sfmedium';
    padding-top: 6px;
}

.pricing_tab sup {
    font-size: 10px;
    position: relative;

    top: -11px;
    left: 1px;
}

.pricing_tab p {
    padding-top: 9%;
    font-family: 'sfbold';
    margin-bottom: 0;
}

p.para_pricing_1 {
    line-height: 0;
}

li.stripted {
    background: #b55e09;
}

li.stripted1 {
    background: #9d9d9d;
}

li.stripted2 {
    background: #d1b100;
}

li.stripted3 {
    background: #81a78d;
}

ul.pricing1 {
    padding: 0;
    margin: 0;
    margin-top: 2px;
}

ul.pricing1 li {
    list-style: none;
    font-size: 0.9rem;
    font-family: 'sfmedium';
    padding-top: 7px;
    padding-bottom: 11px;
    text-align: right;
    margin-right: -10px;
    padding-right: 17px;
}

li.strip {
    background: #e9e9e9;
}

h1.text-center.head_deatiils_12 {
    font-size: 3rem;
    font-family: 'sfbold';
    padding-top: 30px;
    padding-bottom: 30px;
}

img.img_12 {
    width: 75%;
    margin: 0 auto;
    display: block;
    border-radius: 15px;
    object-fit: cover;
}

img.img_12-c {
    width: 75%;
    margin: 0 auto;
    display: block;
    border-radius: 15px;
    object-fit: cover;
}

p.para_12 {
    text-align: center;
    font-family: 'sfmedium';
    padding-top: 30px;
    padding-bottom: 30px;
    max-width: 75%;
    margin: 0 auto;
    color: #fff;
    font-size: 1.3rem;
}


.bg-liner {
    background: linear-gradient(360deg, #606a70, transparent);
    padding-bottom: 2rem;
}


@media(max-width: 767px) {
    .bg-image {
        height: auto;
    }

    .height_footer {
        height: auto;
    }

    p.para_copy {
        position: inherit;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    ul.footer_social {
        margin: 0 auto;
        display: block;
        text-align: center;
        padding: 0;
        margin-bottom: 15px;
    }

    h1.links_calss {
        padding-bottom: 0;
    }

    .grid-1-5 {
        margin-bottom: 20px;
    }

    ul.pricing1 li {
        text-align: center;
    }

    .app-body {
        margin-top: 0 !important;
    }

    li.nav-item a {
        text-align: center;
    }

    button.sign_btn {
        margin: 12px auto;
        display: block;
        position: relative;
        left: 8px;
    }
}

.custom_class_detail {
    position: relative;
}

.know-more {
    color: #000;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    background: #fff;
    height: 33px;
    line-height: 31px;
    border-radius: 4px;
    width: 23%;
    margin: 1.2rem auto;
}

// .d-flex.custom_class_detail {
//     width: 65%;
// }

.ytp-chrome-top.ytp-show-cards-title {
    display: none !important;
}


// div#pricing:before {content: 'Coming Soon...';background: rgba(0,0,0,0.8);font-size: 4rem;position: absolute;left: 0;right: 0;bottom: 0;top: 80px;z-index: 99;text-align: center;color: #fff;font-family: 'sfbold';padding-top: 22rem;}

// div#pricing {
//     position: relative;
// }


// @media(max-width: 1024px)
// {
//     div#pricing 
//     {
//         display: none;
//     }
// }

ol.carousel-indicators li {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid #000;
}

.carousel.slide {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background: #041622;
}

p.para_offer {
    font-size: 2rem;
    color: #fff;
    font-family: 'sfmedium';
}

@media(min-width: 768px) {
    .carousel-item img {
        height: 550px;
        border: 3px solid #000;
        // object-fit: cover;
    }
}

.carousel-item img {
    border: 3px solid #ccc;
}

button.video-react-big-play-button {
    right: 0 !important;
    margin: 9rem auto !important;
}

@media(max-width: 767px) {
    html {
        font-size: 70%;
    }

    button.video-react-big-play-button {
        margin: 4rem auto !important;
    }

    .services_box {
        min-height: 280px;
    }

    .services_box h1 {
        // font-size: 1.0rem;
        padding-top: 15px;
        font-family: 'sfbold';
        line-height: 20px;
    }
}

.container.group {
    margin-left: -6rem;
}

button.sign_btn:hover:after {
    content: 'coming soon!';
    display: block;
    font-size: 14px;
}


.carousel-caption.d-none.d-md-block {
    position: absolute;
    top: 0;
}

.carousel-caption.d-none.d-md-block h3 {
    font-size: 2.5rem;
    font-family: 'sfbold';
    margin-top: -15px;
}

.carousel-caption.d-none.d-md-block p {
    display: none;
}

.navbar-light .navbar-toggler-icon {
    filter: invert(1);
}

@media(max-width: 767px) {
    .services_box_rnd {
        margin-bottom: 20px;
    }

    .services_box_rnd img {
        width: 100%;
        border: 6px solid #fff;
        border-radius: 15px;
        height: 140px;
        object-fit: cover;
    }

    .services_box_rnd h1 {
        position: absolute;
        top: 40px;
        font-size: 1.5rem;
        text-align: center;
        left: 7px;
        right: 8px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        padding-top: 5px;
        padding-bottom: 0;
        font-family: 'sfbold';
        height: 56px;
    }

    .padding {
        padding-top: 0;
    }
}

@media(max-width:767px) {
    .grid-1-5 {
        min-height: auto;
        float: left;
        width: 31%;
        margin-left: 7px;
    }

    .container.group {
        margin-left: 0;
    }

    .button {
        margin: 0 auto;
        position: inherit;
        left: auto;
        // bottom: 0;
        position: relative;
        top: 2px;
    }

    div#pricing:before {
        top: 65px;
    }

    .video-react .video-react-big-play-button {
        top: 3rem !important;
    }
}

h1.offer_what {
    text-align: center;
    margin-top: -10px;
}

@media(min-width: 1024px) {
    .padding-left-quick {

        padding-left: 90px;
        position: relative;
        left: 6%;

    }

}

@media(min-width: 320px) and (max-width: 768px) {
    .padding-left-quick {

        padding-top: 14%;

    }

    // .container.group {
    //     display: flex;
    //     overflow-x: scroll;
    // }
}

@media(max-width: 767px) {
    .pricing_tab {
        min-height: 110px;
        padding-top: 7px;
    }

    ul.pricing1 li {
        font-size: 0.8rem;
    }

    .grid-1-5 {
        padding-bottom: 15px;
    }

    p.para_pricing_1 {
        line-height: 11px;
        margin-bottom: 7px;
    }

    .grid-1-5.color-bg-p-3 {
        margin-left: 35%;
    }

    .pricing_tab sup {
        font-size: 7px;
        position: relative;
        top: -10px;
        left: 0px;
    }
}

@media(min-width: 768px) and (max-width: 1024px) {
    .container.group {
        margin-left: 0rem;
    }

    .grid-1-5 {
        width: 33%;
    }

    .grid-1-5.color-bg-p-3 {
        margin-left: 33%;
    }

    .grid-1-5 {
        margin-bottom: 15px;
        margin-left: 0px;
    }

    .services_box_rnd h1 {
        font-size: 1.1rem;
    }

    .padding-left-quick {
        padding-left: 0;
        position: relative;
        left: 0;
    }
}

.padding-left-quick {
    top: 10rem;
}

.banner_about {
    background-image: url('/img/Background_about.jpg');
    background-size: cover;
}

.para_font_about p {
    font-size: 16px;
}

.container.para_font_about {
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 1200px !important;
}

.container.para_font_about p {
    font-size: 24px;
    font-family: 'sfbold';
    text-align: left;
    color: #000;
    font-weight: 900;
    padding-left: 60px;
}

@media(max-width: 767px) {

    .container.para_font_about p {
        padding-left: 0;
        font-size: 12px;
    }

    .container.para_font_about {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

hr {
    border-color: #fff;
    padding-bottom: 1.2rem;
}

.buttom-play {
    width: 27%;
    margin: 0 auto;
    display: block;
    height: 50px;
    background: #14354b;
    color: #fff;
    outline: none;
    border-radius: 0px;
    border-radius: 10px;
    border: 1px solid #14354b;
    margin-top: 50%;
}

.mtplayer {
    margin-top: 2rem;
    // height: 290px;
    background: #000;
}

button.live_btn {
    position: absolute;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 4rem;
    display: block;
    text-align: center;
    left: 30%;
    top: 13rem;
    background: transparent;
    -webkit-text-stroke: 2px #b5c4ce;
}

// .image-toggle-container {
//     position: relative;
// }

.image {
    position: absolute;
    width: 100%;
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
    height: 500px;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    height: 100%;
}

.show1 {
    transform: scale(0);
}

.hide {
    transform: scale(1);
}

.img {
    width: 80%;
    height: 500px;
    border-radius: 10px;
    display: block;
    text-align: center;
    margin: 0 auto;
    backface-visibility: hidden;
}

@media (max-width: 767px) {
    .img {
        height: auto;
        width: 90%;
    }

    button.live_btn {
        left: 24%;
        top: 9rem;
        font-size: 2rem;
    }

    .buttom-play {
        margin-top: 73%;
    }

    .mtplayer {
        height: auto;
    }

    .padding-left-quick {
        top: 0;
    }
}

.video-react .video-react-big-play-button {
    // display: none;
    transition: all 500ms ease !important;
    opacity: 0 !important;
}

.video-height:hover .video-react .video-react-big-play-button {
    opacity: 1 !important;

    transition: all 500ms ease;

}

.video-react .video-react-big-play-button.big-play-button-hide {
    opacity: 0 !important;
}

button.back_btn {
    background: #14354b;
    border-radius: 6px;
    color: #fff;
    border: none;
    margin: 0 auto;
    display: block;
    height: 46px;
    padding: 0px 32px;
    margin-top: 1rem;
}